import React from 'react'
import { selectorKeys } from '@src/constants/api'
import { CellTypes } from '@src/interfaces/data'
import { ImportTimelineEventsInterface } from '@src/interfaces/importTimelineEvents'
import {
  GenericEditableTableColumn,
  RadioSelectInputCell,
  TextCell,
} from '@src/features/GenericEditableTable/components'

type ImportTimelineEventsColumn =
  GenericEditableTableColumn<ImportTimelineEventsInterface>

export const importTimelineEventsEmployeeEmailColumn: ImportTimelineEventsColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'employee_email',
    dataPoint: 'employee_email',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Employee Email',
    insert: ({ data }) => (
      <TextCell data={data} field="employee_email" onChange={onChange} />
    ),
  })

export const importTimelineEventsFieldNameColumn: ImportTimelineEventsColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'field_name',
    dataPoint: 'field_name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.timeline_field_names,
    title: 'Field Name',
    insert: ({ data }) => (
      <RadioSelectInputCell
        data={data}
        field="field_name"
        onChange={onChange}
        selector={selectorKeys.timeline_field_names}
        selectorField="name"
      />
    ),
  })

export const importTimelineEventsChangedFromColumn: ImportTimelineEventsColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'changed_from',
    dataPoint: 'changed_from',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Changed From',
    insert: ({ data }) => (
      <TextCell data={data} field="changed_from" onChange={onChange} />
    ),
  })

export const importTimelineEventsChangedToColumn: ImportTimelineEventsColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'changed_to',
    dataPoint: 'changed_to',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Changed To',
    insert: ({ data }) => <TextCell data={data} field="changed_to" onChange={onChange} />,
  })

export const importTimelineEventsEffectivetDateColumn: ImportTimelineEventsColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'effective_date',
    dataPoint: 'effective_date',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Effective Date',
    insert: ({ data }) => (
      <TextCell data={data} field="effective_date" onChange={onChange} />
    ),
  })
