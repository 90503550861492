import React from 'react'
import { API } from '@src/constants/api'
import {
  importTimelineEventsChangedFromColumn,
  importTimelineEventsChangedToColumn,
  importTimelineEventsEffectivetDateColumn,
  importTimelineEventsEmployeeEmailColumn,
  importTimelineEventsFieldNameColumn,
} from '@src/constants/columns/importTimelineEvents'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { RowInterface } from '@src/interfaces/data'
import { ImportTimelineEventsInterface } from '@src/interfaces/importTimelineEvents'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { BulkDataUploadV2 } from '@src/features/BulkDataUpload'
import { CommonGenericEditableTableRowOptions } from '@src/features/GenericEditableTable/GenericEditableTable'
import { BulkDeleteButton } from '@src/features/GenericEditableTable/components'

const row = (
  options: CommonGenericEditableTableRowOptions,
): RowInterface<ImportInterface<ImportTimelineEventsInterface>> => ({
  cells: [
    {
      ...importTimelineEventsEmployeeEmailColumn(options.onChange),
      width: 200,
    },
    {
      ...importTimelineEventsFieldNameColumn(options.onChange),
      width: 200,
    },
    {
      ...importTimelineEventsChangedFromColumn(options.onChange),
      width: 200,
    },
    {
      ...importTimelineEventsChangedToColumn(options.onChange),
      width: 200,
    },
    {
      ...importTimelineEventsEffectivetDateColumn(options.onChange),
      width: 200,
    },
  ],
})

export const ImportTimelineEvents = () => {
  return (
    <BulkDataUploadV2
      apiEndpoint={API.TIMELINE_BULK_UPLOAD}
      apiVersion="v2"
      anyRoute={ROUTES.FORMS.IMPORT_DATA.TIMELINE_EVENTS.ANY}
      importRoute={ROUTES.FORMS.IMPORT_DATA.TIMELINE_EVENTS.IMPORT}
      sessionRoute={ROUTES.FORMS.IMPORT_DATA.TIMELINE_EVENTS.SESSION}
      category="employee_timeline_bulk_upload"
      entity="event"
      name="event"
      row={row}
      tableActions={tableActionsProps => <BulkDeleteButton {...tableActionsProps} />}
      tableName={TableNames.UploadTimelineEvents}
      header={
        <PageHeader
          backUrl={ROUTES.FORMS.EMPLOYEES_BULK_IMPORT}
          title="Import timeline events"
        />
      }
    />
  )
}
